import cto from "../images/cto.png";
import ceo from "../images/ceo.png";
import manager from "../images/manager.png";
import adam from "../images/adam.png";

export const teamdata = [
  {
    avatar: ceo,
    role: "CEO",
    name: "Demeter Márton Csaba",
    contact_email: "info@chimerasolutions.ai",
    phone: "+36204942596",
    intro:
      "I am a biophysicist by training. Highly motivated and eager-to-learn, determined to work in research, innovation, and development. My interest focuses on multiple subjects such as physics-related problem solving, coding simulations, developing computer vision applications, video-editing, and sound design. As an entrepreneur, I am highly motivated to create, work and manage projects.",
    link: [
      {
        title: "Curriculum Vitae",
        link: "https://drive.google.com/file/d/19AmY2WGSatdxPUs1QkmYcLlwG03xgIir/view",
      },
      {
        title: "LinkedIn",
        link: "https://www.linkedin.com/in/m%C3%A1rton-demeter-133406169/",
      },
      {
        title: "GitHub",
        link: "https://github.com/pentadotddot",
      },
      {
        title: "Twitter",
        link: "https://twitter.com/chimeramultimed",
      },
      {
        title: "SoundCloud",
        link: "https://soundcloud.com/penta-d",
      },
      {
        title: "Upwork",
        link: "https://www.upwork.com/freelancers/~0187d9f8f2921690f0",
      },
    ],
    hobby: [
      "Music",
      "Art-films",
      "Science",
      "Philosohphy",
      "Nature",
      "Workout",
    ],
    language: ["Python", "C++", "Javascript", "G-script (Godot engine)"],
    frame: ["PyTorch", "Tensorflow", "Three.js", "OpenCV", "React/Next.js"],
    software: ["Adobe Suite", "Figma", "Sony Vegas", "Ableton Live", "Blender"],
    softskill: [
      "Management",
      "Communication",
      "Team player",
      "Reliability",
      "Punctuality",
    ],
  },
  {
    avatar: cto,
    role: "COO",
    name: "Török Gábor",
    contact_email: "info@chimerasolutions.ai ",
    phone: "",
    intro:
      "I am a software developer, highly motivated to learn new technologies and use them in my projects. I am keen on researching Artificial Intelligence publications and finding the best-fit solutions for my clients. Developing sustainable software is my second main passion, and I more than one hundred percent believe that a well-designed framework, code, and structure will make the future easier for all of us. I am a founding member of Chimera Solutions, mainly working on software architecture design and machine learning problems.",
    link: [
      {
        title: "Curriculum Vitae",
        link: "https://drive.google.com/file/d/1b0_RYwSpvOdxjm83ZEUvb2B96BcTMhhF/view",
      },
      {
        title: "LinkedIn",
        link: "https://www.linkedin.com/in/torok-gabor-dev/",
      },
      {
        title: "Upwork",
        link: "https://www.upwork.com/freelancers/~01790f15b5d473860b/",
      },
    ],
    hobby: ["Dance", "Science", "Philosohphy", "Nature"],
    language: ["C++", "Python", "Javascript", "Java"],
    frame: ["Qt", "Angular", "React", "PyTorch", "OpenCV"],
    software: [],
    softskill: [
      "Team player",
      "Reliability",
      "Good Communication",
      "Sincierity",
    ],
  },
];
